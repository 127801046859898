<template>
  <div class="cart-product">
    <div class="cart-product__product">
      <div class="cart-product__container">
        <div class="cart-product__info">
          <ProductLink
            class="cart-product__img-w"
            :product="cartProduct.product"
            @click.prevent="closeCart"
          >
            <img
              class="cart-product__img"
              :src="cartProduct.product.getMainImage('medium')"
              :alt="cartProduct.product.getName()"
            />
          </ProductLink>
          <div class="cart-product__main">
            <ProductLink
              class="cart-product__title"
              :product="cartProduct.product"
              @click.prevent="closeCart"
            >
              {{ cartProduct.product.getName() }}
            </ProductLink>
            <div class="cart-product__code">
              {{ _T("@Code") }}
              <span>{{ cartProduct.product.code }}</span>
            </div>
          </div>
        </div>
        <div class="cart-product__actions">
          <div class="cart-product__counter">
            <div
              class="cart-product__counter-minus"
              @click="decrease(cartProduct)"
            >
              <img src="~/assets/icons/minus.svg" alt="minus" />
            </div>
            <input
              type="number"
              class="cart-product__counter-input"
              min="1"
              max="999"
              :value="cartProduct.quantity"
              @blur="setQuantity($event, cartProduct)"
            />
            <div
              class="cart-product__counter-plus"
              @click="increase(cartProduct)"
            >
              <img src="~/assets/icons/plus.svg" alt="plus" />
            </div>
          </div>
          <div class="cart-product__price" :class="{ discount: hasDiscount }">
            <span v-if="hasDiscount" class="cart-product__price-sale-old">
              {{ priceFormatter(getPreviousAmount) }}
              &#8372;
            </span>
            <span class="cart-product__price-sale-new">
              {{ priceFormatter(cartProduct.totalSalePrice.amount) }}
              &#8372;
            </span>
          </div>
        </div>
      </div>
      <button class="cart-product__del" @click.prevent="remove(cartProduct)">
        <img src="~/assets/icons/trash.svg" alt="delete product" />
      </button>
    </div>
    <template v-if="0 !== cartProduct.product.gifts.length">
      <ProductGift
        v-for="giftProduct in cartProduct.product.gifts"
        :key="giftProduct.id"
        :product="giftProduct"
        :quantity="cartProduct.quantity"
        :close-cart="closeCart"
      />
    </template>
  </div>
</template>

<script setup>
import { priceFormatter } from "~/utils/formatters.utils";
import { useCartStore } from "~/store/cartStore";
import { Product } from "~/models/product.model";
import ProductGift from "~/modules/shared/productGift/ProductGift.vue";

const props = defineProps({
  cartProduct: { type: Object, required: true },
  closeCart: { type: Function, required: true },
});

const cartStore = useCartStore();

const itemQtyAdd = ref(0);
const itemQtyRemove = ref(0);

const hasDiscount = computed(() => {
  return (
    props.cartProduct.product.getPrice(Product.PRICE_TYPE.PREVIOUS) ||
    props.cartProduct.product.getPrice(Product.PRICE_TYPE.CURRENT) >
      props.cartProduct.salePrice.amount
  );
});

const getPreviousAmount = computed(() => {
  return (
    (props.cartProduct.product.getPrice(Product.PRICE_TYPE.PREVIOUS) ||
      props.cartProduct.product.getPrice(Product.PRICE_TYPE.CURRENT)) *
    props.cartProduct.quantity
  );
});

const increase = (item) => {
  item.quantity++;

  itemQtyAdd.value++;
  debounced(item, itemQtyAdd.value);
};

const decrease = (item) => {
  if (item.quantity === 1) {
    return;
  }

  item.quantity--;
  itemQtyRemove.value--;

  debounced(item, itemQtyRemove.value);
};

const setQuantity = (event, item) => {
  if (event.target.value < 1) {
    event.target.value = 1;
  }

  const prevQty = item.quantity;
  const currQty = parseInt(event.target.value);

  if (prevQty !== currQty) {
    debounced(item, currQty - prevQty);
  }
};

const debounced = useDebounceFn((item, qty) => {
  cartStore.setQuantity(item, qty);
  itemQtyAdd.value = 0;
  itemQtyRemove.value = 0;
}, 300);

function remove(item) {
  cartStore.removeItem(item);
}
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cart-product {
  width: 100%;

  @include flex-container(column, flex-start, flex-end);
  gap: 8px;

  padding-bottom: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-sky-base);
  }

  &__product {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 24px;

    padding-block: 8px;

    @include mobile {
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__container {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    @include mobile {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__img {
    @include fixedHW(64px);
    border-radius: 8px;
  }

  &__info {
    max-width: 530px;
    width: 100%;

    @include flex-container(row, flex-start, center);
    gap: 16px;

    @include mobile {
      gap: 8px;
    }
  }

  &__main {
    @include flex-container(column, center);
    gap: 4px;
  }

  &__title {
    @include font(16, 22, 600);
    letter-spacing: 0.02em;

    transition: color 0.2s ease;

    @include mobile {
      @include font(14, 20, 500);
    }

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__code {
    @include font(12, 16);
    letter-spacing: 0.02em;
  }

  &__actions {
    max-width: 240px;
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 8px;
  }

  &__counter {
    max-width: 124px;
    width: 100%;

    @include flex-container(row, center, center);
    gap: 8px;

    border: 1px solid #e3e5e6;
    border-radius: 60px;

    padding: 8px 12px;

    @include mobile {
      max-width: 88px;

      gap: 4px;

      padding: 4px 8px;
    }
  }

  &__counter-minus {
    cursor: pointer;

    @include mobile {
      width: 16px;
    }
  }

  &__counter-input {
    width: 36px;

    @include font(14, 24);
    text-align: center;

    cursor: auto;

    @include mobile {
      @include font(12, 16);
    }
  }

  &__counter-plus {
    cursor: pointer;

    @include mobile {
      width: 16px;
    }
  }

  &__price {
    @include font(18, 26, 700);
    letter-spacing: 0.02em;

    &.discount {
      @include flex-container(column, center, center);
      gap: 4px;

      .cart-product__price-sale-new {
        color: red;
      }
    }
  }

  &__price-sale {
    @include flex-container(column, center, flex-end);
    gap: 8px;

    @include mobile {
      gap: 4px;
    }
  }

  &__price-sale-old {
    @include font(14, 20);
    letter-spacing: 0.02em;
    text-decoration: line-through;

    @include mobile {
      @include font(12, 16);
    }
  }

  &__price-sale-new {
    @include font(18, 26, 700);
    letter-spacing: 0.02em;

    @include mobile {
      @include font(14, 20, 700);
    }
  }

  &__del {
    width: fit-content;
    height: fit-content;
  }
}
</style>
